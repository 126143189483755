import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Layout from 'components/layout';
import SmallImageHero from 'components/hero/SmallImageHero';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';
import { Tag, Share } from 'components/ui';
import { Helmet } from 'react-helmet';

const styles = {
  body: {
    textTransform: 'none',
    letterSpacing: '0.75px',
  },
};

function BasicPage(props) {
  const { classes } = props;
  const doc = props.data.prismic.allBasic_pages.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout>
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <SmallImageHero />
      <Container maxWidth="lg">
        <Box py={10}>
          {doc.node.content_tagging.length > 0 &&
            doc.node.content_tagging[0].tag && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="row"
                mb={4}
              >
                {doc.node.content_tagging.map((item, key) => {
                  if (item) {
                    return <Tag data={item.tag[0].text} key={key} />;
                  }
                })}
              </Box>
            )}
          {doc.node.content_title && doc.node.content_title[0] && (
            <Typography component="h1" variant="h1" align="center">
              {doc.node.content_title[0].text}
            </Typography>
          )}
        </Box>
      </Container>
      <Container maxWidth="md">
        <Box>
          <Typography
            component="div"
            variant="subtitle1"
            className={classes.body}
          >
            <RichText render={doc.node.body} />
          </Typography>
        </Box>
        {doc.node.show_share_options && doc.node.content_title && (
          <Box>
            <Share
              url={doc.node._meta.uid}
              title={doc.node.content_title[0].text}
            />
          </Box>
        )}
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query BasicTypePageQuery($uid: String) {
    prismic {
      allBasic_pages(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            metadata {
              description
              keywords
              title
            }
            banner_image
            banner_title
            banner_imageSharp {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            content_title
            body
            content_tagging {
              tag
            }
            show_share_options
          }
        }
      }
    }
  }
`;

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BasicPage);
